<template>
  <div>
    <div
      ref="container"
      class="article"
      v-html="content"
      @click="onClick"
    ></div>
    <ImageViewer ref="imageViewer" />
  </div>
</template>
<script>
import ImageViewer from '../ImageViewer';
import ArticleGallery from './ArticleGallery';
import Vue from 'vue';
import {
  getSingleImages,
  getGalleries,
} from './template-helpers/images.helpers.ts';
import loadImage from '@themes/v1/fns/load-image';
import { makePathWithLangAndPlatform } from '../../fns/router';
import { animationFrameDebounce } from '../../fns/utils';

export default {
  props: ['content'],
  components: {
    ImageViewer,
  },
  data: () => ({
    scrollSpyFn: null,
  }),
  mounted() {
    const container = this.$refs.container;
    getSingleImages(container).map((image) => {
      image.element.classList.add('loaded');
      loadImage(image.preview).then(() => {
        image.element.style.backgroundImage = `url(${image.preview})`;
        image.element.classList.remove('loaded');
        image.element.classList.add('scalable-image');
      });
      image.element.addEventListener('click', () => {
        this.imageClick(image);
      });
    });
    getGalleries(container).map((item) => {
      this.initGallery(item.el, item.items);
    });
    this.fixLinks(container);
    this.startScrollSpy(container);
  },
  destroyed() {
    if (this.scrollSpyFn)
      window.removeEventListener('scroll', this.scrollSpyFn);
  },
  methods: {
    imageClick(image) {
      this.$refs.imageViewer.open(image.group ? image.group.items : [image]);
    },
    onClick(event) {
      let { target } = event;
      while (target && target.tagName !== 'A') {
        target = target.parentNode;
      }
      if (target && target.tagName === 'A') {
        const href = target.getAttribute('href');
        if (/^\/\w/i.test(href)) {
          event.preventDefault();
          this.$router.push(href);
        }
      }
    },
    initGallery(element, items) {
      element.classList.add(`article__gallery--${items.length}-items`);
      const galleryComponentClass = Vue.extend(ArticleGallery);
      const instance = new galleryComponentClass();
      instance.$store = this.$store;
      instance.$mount();
      element.innerHTML = '';
      element.appendChild(instance.$el);
      instance.initCarousel(items, (item, group) => {
        const index = group.findIndex((i) => i.src === item.src);
        this.$refs.imageViewer.open(group, index);
      });
    },
    fixLinks(element) {
      const links = Array.from(element.getElementsByTagName('A'));
      links
        .filter((link) => /^\/\w/i.test(link.getAttribute('href')))
        .forEach((link) => {
          link.href = makePathWithLangAndPlatform(
            this.$route,
            link.getAttribute('href')
          );
        });
      links
        .filter((link) => /^https?:\/\//i.test(link.getAttribute('href')))
        .forEach((link) => {
          link.setAttribute('target', '_blank');
        });
    },
    startScrollSpy(container) {
      const elements = Array.from(container.querySelectorAll('[id]'));
      if (elements.length === 0) return;
      let lastElement = null;
      const topEdge = this.$layout().ingame ? 10 : 70;
      const bottomEdge = window.innerHeight / 2;
      this.scrollSpyFn = animationFrameDebounce(() => {
        let last = null;

        for (let element of elements) {
          const rect = element.getBoundingClientRect();
          if (rect.y < bottomEdge) {
            last = element;
            if (rect.y > topEdge) break;
          } else break;
        }
        if (last != lastElement) {
          lastElement = last;
          const id = lastElement != null ? lastElement.id : '';
          this.$emit('scrollSpy', id);
        }
      });
      window.addEventListener('scroll', this.scrollSpyFn);
      this.scrollSpyFn();
    },
  },
};
</script>
