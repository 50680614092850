
















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import BoxContent from './Box/BoxContent.vue';

import { Review, ReviewReaction } from '@/modules/guide/reviews';
import config from '@/env';
import { LanguageCode } from '@/core/system';

@Component({ components: { ValidationProvider, BoxContent } })
export default class HelpfulQuest extends Vue {
  @Prop()
  readonly article!: any;

  @Prop()
  readonly reviews!: { [path: string]: Review };

  @Prop()
  readonly language!: LanguageCode;

  get value(): Review | null {
    if (this.article == null) {
      return null;
    }
    const review = this.reviews[this.article.path];
    if (review != null) {
      return review;
    }
    return {
      game: config.game,
      language: this.language,
      articlePath: this.article.path,
    };
  }

  get showInput() {
    return this.value?.reaction === ReviewReaction.Dislike && this.editable;
  }

  get editable() {
    return this.value?.closed !== true;
  }

  comment = '';

  saveReaction(reaction: ReviewReaction): void {
    if (this.value == null) {
      return;
    }
    const review: Review = {
      ...this.value,
      reaction,
      closed: reaction === ReviewReaction.Like,
    };
    this.$emit('change', review);
  }

  submit() {
    if (this.value == null) {
      return;
    }
    const review: Review = {
      ...this.value,
      message: this.comment,
      closed: true,
    };
    this.$emit('change', review);
  }
}
