import { Section } from '@/modules/guide/environment';
import { AppStore } from '../stores/app.store';

export const getSectionByPath = function (
  appStore: AppStore,
  path: string
): Section | null {
  const matching = /^\/([A-Za-z0-9-]+)\//.exec(path);
  if (matching == null) {
    return null;
  }
  const sectionPath = matching[1];
  return appStore.sections[sectionPath];
};
