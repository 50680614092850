var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.article)?_c('div',{staticClass:"section-box"},[_c('QuestionMark'),_c('Breadcrumbs',{staticClass:"mobile-hide",attrs:{"links":_vm.breadcrumbs,"current":_vm.article.name}}),(_vm.$store.state.system.device.isDesktopOrTablet)?_c('TwoColumn',{attrs:{"aux-class":"sm--hide"}},[_c('h1',{staticClass:"m-t--lv0 m-t-xs--lv4 m-b--lv4"},[_vm._v(_vm._s(_vm.article.name))])]):_c('h1',{staticClass:"m-t--lv0 m-t-xs--lv4 m-b--lv4"},[_vm._v(_vm._s(_vm.article.name))]),(_vm.$store.state.system.device.isDesktopOrTablet)?_c('TwoColumn',{attrs:{"aux-class":'mobile-hide'}},[_c('Box',{staticClass:"box--mobile-full-width",attrs:{"theme":"dark","opacity":_vm.$bps('xs') ? 70 : 80}},[_c('BoxContent',[_c('ArticleContent',{key:_vm.article.name,attrs:{"content":_vm.article.content},on:{"scrollSpy":function($event){_vm.scrollSpyId = $event}}})],1)],1),(_vm.article && !_vm.isSimplePage)?_c('Box',{staticClass:"m-t--lv2",attrs:{"theme":"dark","opacity":_vm.$bps('xs') ? 70 : 80}},[_c('BoxContent',{attrs:{"space":"none"}},[_c('HelpfulQuest',{attrs:{"article":_vm.article,"reviews":_vm.$store.state.guide.reviews,"language":_vm.$route.params.lang},on:{"change":function($event){return _vm.saveReview($event)}}})],1)],1):_vm._e(),(_vm.tableOfContents.length || _vm.topics.length || !_vm.isSimplePage)?_c('Sticky',{attrs:{"slot":"right"},slot:"right"},[(
          (_vm.article.tableOfContents != null &&
            _vm.article.tableOfContents.length) ||
          (_vm.article.topics != null && _vm.article.topics.length)
        )?_c('TopicsBlock',{staticClass:"m-b--lv2",attrs:{"topics":_vm.topics,"tableOfContent":_vm.tableOfContents,"currentPath":_vm.article.path,"tocCurrent":_vm.scrollSpyId}}):_vm._e(),(!_vm.isSimplePage)?_c('AskBox',{staticClass:"mobile-hide"}):_vm._e()],1):_vm._e()],1):[_c('Box',{attrs:{"theme":"dark","disableTopDecoration":"","disableBottomDecoration":"","opacity":_vm.$bps('xs') ? 70 : 80}},[_c('BoxContent',{staticClass:"mobile-column-inner--sm"},[_c('ArticleContent',{key:_vm.article.name,attrs:{"content":_vm.article.content},on:{"scrollSpy":function($event){_vm.scrollSpyId = $event}}})],1)],1),(_vm.article && !_vm.isSimplePage)?_c('Box',{staticClass:"m-t--lv2 mobile-column-outer",attrs:{"opacity":_vm.$bps('xs') ? 70 : 80,"theme":"dark"}},[_c('BoxContent',{attrs:{"space":"none"}},[_c('HelpfulQuest',{attrs:{"article":_vm.article,"reviews":_vm.$store.state.guide.reviews,"language":_vm.$route.params.lang},on:{"change":function($event){return _vm.saveReview($event)}}})],1)],1):_vm._e()],_c('RelatedMaterials',{staticClass:"mobile-column-outer",attrs:{"items":_vm.article.relatedMaterials}}),(!_vm.isSimplePage)?_c('NeedSomethingElse',{staticClass:"mobile-only m-t--lv0 mobile-column-outer"}):_vm._e(),_c('ScrollTop',{staticClass:"m-b-xs--lv0"}),(
      (_vm.article.tableOfContents != null && _vm.article.tableOfContents.length) ||
      (_vm.article.topics != null && _vm.article.topics.length)
    )?_c('TopicsDrawer',{attrs:{"topics":_vm.topics,"tableOfContent":_vm.tableOfContents,"currentPath":_vm.article.path,"tocCurrent":_vm.scrollSpyId}}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }