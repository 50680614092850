<template>
  <div v-if="cards" class="related-materials">
    <h2 class="large m-t--lv0 m-b--lv2">
      {{ $t('phrases.related materials') }}
    </h2>
    <div class="related-materials__carousel-container">
      <Carousel
        :hide-bullet="
          cards.length < 3 ||
          (cards.length === 3 && !$bps(['xs', 'sm', 'md', 'lg']))
        "
        arrows="small"
        :count-per-slide="3"
        :focusAt="0"
        :breakpoints="breakpoints"
        :type="cards.length < 3 ? 'slider' : 'carousel'"
        :gap="0"
        class="mobile-hide"
      >
        <CarouselItem v-for="item of cards" :key="item.paths">
          <div class="related-materials__slide">
            <router-link
              :to="item.path"
              @click.native="
                $track('game_guide_page', 'click_game_guide_related', item.title)
              "
            >
              <div class="related-card">
                <div class="related-card__image">
                  <div class="related-card__image-filler"></div>
                  <BgImage
                    :url="item.imageUrl"
                    fallback-image-class="related-default-image"
                  ></BgImage>
                </div>
                <div class="related-card__content">
                  <h4 class="related-card__title">{{ item.title }}</h4>
                  <p v-if="item.description" class="related-card__text">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
    <ul class="related-materials-list mobile-only">
      <li v-for="item in cards" :key="item.paths">
        <router-link :to="item.path">{{ item.title }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '@/themes/v1/stores/app.store';
import Carousel from '@themes/v1/components/Carousel/Carousel';
import CarouselItem from '@themes/v1/components/Carousel/CarouselItem';
import { getSectionByPath } from '@themes/v1/fns/guide-fns';
import BgImage from '@themes/v1/components/BgImage.vue';

export default {
  props: ['items'],
  components: {
    Carousel,
    CarouselItem,
    BgImage,
  },
  computed: {
    cards() {
      if (this.items == null || this.items.length === 0) {
        return null;
      }
      const appStore = getModule(AppStore, this.$store);
      return this.items.map((item) => {
        const section = getSectionByPath(appStore, item.path);
        const description =
          section &&
          section.articlePageMetadata &&
          section.articlePageMetadata.description
            ? section.articlePageMetadata.description.replace(
                '{description}',
                item.description
              )
            : item.description;

        return {
          ...item,
          path: this.normalizeLink(item.path),
          description,
        };
      });
    },
    breakpoints() {
      return {
        1170: {
          perView: 2,
        },
        3000: {
          dragThreshold: false,
          perView: 3,
        },
      };
    },
  },
  methods: {
    normalizeLink(link) {
      return `/${this.$route.params.platform}/${this.$route.params.lang}${link}`;
    },
  },
};
</script>
