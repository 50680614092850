






import { Component, Vue, Prop } from 'vue-property-decorator';
import { OnDestroyed, OnMounted } from '@/core/vue.types';

@Component
export default class ScrollTop extends Vue implements OnMounted, OnDestroyed {
  visible = false;

  @Prop({ type: Number, default: 150 })
  offset!: number;

  mounted() {
    if (process.server || !window) return;
    window.addEventListener('scroll', this.listener);
  }

  destroyed() {
    window.removeEventListener('scroll', this.listener);
  }

  listener() {
    this.visible = window.scrollY > this.offset;
  }

  onTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
