<template>
  <div v-if="article" class="section-box">
    <QuestionMark></QuestionMark>
    <Breadcrumbs
      :links="breadcrumbs"
      :current="article.name"
      class="mobile-hide"
    />
    <TwoColumn aux-class="sm--hide" v-if="$store.state.system.device.isDesktopOrTablet">
      <h1 class="m-t--lv0 m-t-xs--lv4 m-b--lv4">{{ article.name }}</h1>
    </TwoColumn>
    <h1 v-else class="m-t--lv0 m-t-xs--lv4 m-b--lv4">{{ article.name }}</h1>

    <TwoColumn
      v-if="$store.state.system.device.isDesktopOrTablet"
      :aux-class="'mobile-hide'"
    >
      <Box
        theme="dark"
        :opacity="$bps('xs') ? 70 : 80"
        class="box--mobile-full-width"
      >
        <BoxContent>
          <ArticleContent
            :key="article.name"
            :content="article.content"
            @scrollSpy="scrollSpyId = $event"
          />
        </BoxContent>
      </Box>
      <Box
        v-if="article && !isSimplePage"
        theme="dark"
        :opacity="$bps('xs') ? 70 : 80"
        class="m-t--lv2"
      >
        <BoxContent space="none">
          <HelpfulQuest
            :article="article"
            :reviews="$store.state.guide.reviews"
            :language="$route.params.lang"
            @change="saveReview($event)"
          ></HelpfulQuest>
        </BoxContent>
      </Box>
      <Sticky
        v-if="tableOfContents.length || topics.length || !isSimplePage"
        slot="right"
      >
        <TopicsBlock
          v-if="
            (article.tableOfContents != null &&
              article.tableOfContents.length) ||
            (article.topics != null && article.topics.length)
          "
          :topics="topics"
          :tableOfContent="tableOfContents"
          :currentPath="article.path"
          :tocCurrent="scrollSpyId"
          class="m-b--lv2"
        />
        <AskBox v-if="!isSimplePage" class="mobile-hide"></AskBox>
      </Sticky>
    </TwoColumn>
    <template v-else>
      <Box
        theme="dark"
        disableTopDecoration
        disableBottomDecoration
        :opacity="$bps('xs') ? 70 : 80"
      >
        <BoxContent class="mobile-column-inner--sm">
          <ArticleContent
            :key="article.name"
            :content="article.content"
            @scrollSpy="scrollSpyId = $event"
          />
        </BoxContent>
      </Box>
      <Box
        :opacity="$bps('xs') ? 70 : 80"
        v-if="article && !isSimplePage"
        theme="dark"
        class="m-t--lv2 mobile-column-outer"
      >
        <BoxContent space="none">
          <HelpfulQuest
            :article="article"
            :reviews="$store.state.guide.reviews"
            :language="$route.params.lang"
            @change="saveReview($event)"
          ></HelpfulQuest>
        </BoxContent>
      </Box>
    </template>
    <RelatedMaterials
      :items="article.relatedMaterials"
      class="mobile-column-outer"
    ></RelatedMaterials>
    <NeedSomethingElse
      v-if="!isSimplePage"
      class="mobile-only m-t--lv0 mobile-column-outer"
    ></NeedSomethingElse>
    <ScrollTop class="m-b-xs--lv0"></ScrollTop>
    <TopicsDrawer
      v-if="
        (article.tableOfContents != null && article.tableOfContents.length) ||
        (article.topics != null && article.topics.length)
      "
      :topics="topics"
      :tableOfContent="tableOfContents"
      :currentPath="article.path"
      :tocCurrent="scrollSpyId"
    />
  </div>
</template>
<script>
import RelatedMaterials from './RelatedMaterials.vue';
import Box from '../../../components/Box/Box.vue';
import BoxContent from '../../../components/Box/BoxContent.vue';
import AskBox from '../../../components/AskBox.vue';
import TopicsBlock from './topics/TopicsBlock.vue';
import ArticleContent from '../../../components/ArticleContent/ArticleContent.vue';
import Breadcrumbs from '../../../components/Breadcrumbs.vue';
import config from '@/env';

import HelpfulQuest from '../../../components/HelpfulQuest.vue';
import { ReviewReaction } from '@/modules/guide/reviews';
import Sticky from '@/themes/v1/components/Sticky.vue';
import ScrollTop from '@/themes/v1/components/ScrollTop.vue';
import TwoColumn from '@/themes/v1/components/TwoColumn.vue';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import TopicsDrawer from './topics/TopicsDrawer.vue';
import NeedSomethingElse from '@/themes/v1/components/NeedSomethingElse.vue';
import QuestionMark from '@/themes/v1/components/QuestionMark.vue';

export default {
  name: 'ArticleView',
  components: {
    TwoColumn,
    ScrollTop,
    Sticky,
    Breadcrumbs,
    ArticleContent,
    TopicsBlock,
    AskBox,
    HelpfulQuest,
    BoxContent,
    Box,
    RelatedMaterials,
    TopicsDrawer,
    NeedSomethingElse,
    QuestionMark,
  },
  data() {
    return {
      isSimplePage: false,
      scrollSpyId: '',
      trackReadArticleTimeoutId: 0,
    };
  },
  inject: ['articleService', 'reviewsService'],
  async asyncData(ctx) {
    const path = `/${ctx.route.params.section}/${ctx.route.params.pathMatch}`;
    const articleService = resolveFromCtx('articleService', ctx);
    let article;
    try {
      article = await articleService.getItem(
        config.game,
        ctx.app.$i18n.locale,
        path
      );
    } catch (error) {
      if (error.response.status >= 300 && error.response.status < 310) {
        const redirectedPath = error.response.data;
        const path = ctx.app.$routerHelper.withPlatformAndLang(
          ctx.route,
          redirectedPath
        );
        ctx.redirect({ path }, error.response.status);
        return;
      } else if (error.response.status === 404) {
        ctx.error('Article not found', 404);
        return;
      } else {
        ctx.error(error, 500);
        return;
      }
    }
    // const guideStore = getModule(GuideStore, ctx.store);
    // guideStore.setArticle(article);
    ctx.store.commit('guide/setArticle', article);
    return {
      // article,
      isSimplePage: ctx.route.params.section === 'pages',
    };
  },
  async prefetch() {
    this.setSection();
  },
  mounted() {
    this.trackReadArticleDelayed();
    window.addEventListener('resize', this.adjustScrollingOffset);
    this.adjustScrollingOffset();
  },
  destroyed() {
    if (this.trackReadArticleTimeoutId) {
      clearTimeout(this.trackReadArticleTimeoutId);
    }
    this.resetScrollingOffset();
    window.removeEventListener('resize', this.adjustScrollingOffset);
  },
  computed: {
    article() {
      return this.$store.state.guide.article;
    },
    getArticlePath() {
      return `/${this.$route.params.section}/${this.$route.params.pathMatch}`;
    },
    breadcrumbs() {
      const segment = this.$route.params.section;
      const section = this.$store.getters['app/sectionsList'].find(
        (s) => s.pathSegment === segment
      );
      let result = [];
      if (this.article && this.article.breadcrumbs) {
        result = result.concat(this.article.breadcrumbs);
      }
      if (section != null && section.hidden !== true) {
        result.unshift({
          link: `/${segment}`,
          title: section ? section.name : segment,
        });
      }
      return result;
    },

    tableOfContents() {
      if (
        this.article.tableOfContents != null &&
        this.article.tableOfContents.length
      )
        return this.article.tableOfContents;
      return [];
    },

    topics() {
      const items =
        this.article.topics != null && this.article.topics.length
          ? this.article.topics
          : [];

      const parent =
        this.article.breadcrumbs.length > 0
          ? {
              link: this.article.breadcrumbs[0].link,
              title: this.article.breadcrumbs[0].title,
            }
          : { link: this.article.path, title: this.article.name };

      return [parent, ...items];
    },

    topicsHeading() {
      let result = { link: this.article.path, title: this.article.name };
      if (this.article.breadcrumbs.length > 0) {
        const parentArticleInfo = this.article.breadcrumbs[0];
        result = {
          link: parentArticleInfo.link,
          title: parentArticleInfo.title,
        };
        return result;
      }
      return result;
    },
  },
  methods: {
    adjustScrollingOffset() {
      const headerLogoRef = document.getElementById('header-panel-logo');
      if (headerLogoRef) {
        const headerLogoHeight = headerLogoRef.offsetHeight + 10;
        const rootElement = document.documentElement;
        rootElement.style['scroll-padding-top'] = `${headerLogoHeight}px`;
      }
    },

    resetScrollingOffset() {
      const rootElement = document.documentElement;
      rootElement.style['scroll-padding-top'] = `auto`;
    },

    setSection() {
      const segment = this.$route.params.section;
      const section = this.$store.getters['app/sectionsList'].find(
        (s) => s.pathSegment === segment
      );
      this.$store.commit('guide/setSection', section || null);
    },

    trackReadArticleDelayed() {
      const delay = 30000; // 30 sec
      this.trackReadArticleTimeoutId = setTimeout(() => {
        this.$track('game_guide_page', 'read_article', this.article.name);
      }, delay);
    },

    trackHelpfulYes() {
      this.$track('game_guide_page', 'click_helpful_yes', this.article.name);
    },
    saveReview(review) {
      this.$store.commit('guide/setReview', {
        path: review.articlePath,
        review,
      });
      this.reviewsService.send(review).then((id) => {
        const item = this.$store.state.guide.reviews[review.articlePath];
        this.$store.commit('guide/setReview', {
          path: review.articlePath,
          review: {
            ...item,
            id,
          },
        });
      });
      if (review.reaction === ReviewReaction.Like) {
        this.trackHelpfulYes();
      }
    },
  },
  metaInfo() {
    const metadata = { title: 'Support Portal', meta: [] };
    const section = this.$store.state.guide.section;
    if (section && section.articlePageMetadata && this.article) {
      if (section.articlePageMetadata.title) {
        metadata.title = section.articlePageMetadata.title.replace(
          /{title}/g,
          this.article.metadata.title
        );
      }
      if (section.articlePageMetadata.description) {
        metadata.meta.push({
          vmid: 'description',
          name: 'description',
          content: section.articlePageMetadata.description.replace(
            /{description}/g,
            this.article.metadata.description
          ),
        });
      }
      if (section.articlePageMetadata.keywords) {
        metadata.meta.push({
          vmid: 'keywords',
          name: 'keywords',
          content: section.articlePageMetadata.keywords
            .replace(/{title}/g, this.article.metadata.title)
            .replace(/{keywords}/g, this.article.metadata.keywords),
        });
      }
    }
    return metadata;
  },
};
</script>
