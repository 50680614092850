<template>
  <div v-if="initialized">
    <Carousel
      :hide-bullet="hideBullets"
      :count-per-slide="items.length < 3 ? items.length : 3"
      :focusAt="0"
      :type="items.length < 4 ? 'slider' : 'carousel'"
      :breakpoints="breakpoints"
      :class="[hideBullets ? '' : 'article__gallery--has-bullets']"
      :gap="0"
      :shortset-bullets="true"
      @slide-click="onClick(items[$event])"
    >
      <CarouselItem v-for="item of items" :key="item.path">
        <div class="article__gallery-image-container">
          <BgImage
            :url="item.preview || item.src"
            class="scalable-image"
          ></BgImage>
        </div>
      </CarouselItem>
    </Carousel>
  </div>
</template>
<script>
import Carousel from '../Carousel/Carousel';
import CarouselItem from '../Carousel/CarouselItem';
import BgImage from '../BgImage';

export default {
  components: {
    Carousel,
    CarouselItem,
    BgImage,
  },
  data: function () {
    return {
      items: null,
      initialized: false,
      clickListener: null,
    };
  },
  methods: {
    initCarousel(items, clickListener) {
      this.items = items;
      this.clickListener = clickListener;
      this.initialized = true;
    },
    onClick(item) {
      this.clickListener(item, this.items);
    },
  },
  computed: {
    breakpoints() {
      if (this.items == null) {
        return null;
      }
      return {
        767: {
          perView: this.items.length < 2 ? this.items.length : 2,
          type: this.items.length < 3 ? 'slider' : 'carousel',
        },
      };
    },
    hideBullets() {
      return (
        this.items.length < 3 ||
        (this.items.length === 3 && !this.$bps(['xs', 'sm']))
      );
    },
  },
};
</script>
