
































































import { Vue, Component } from 'vue-property-decorator';
import Popup from './Popup.vue';
import Spinner from './Spinner.vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ClickOutside = require('vue-click-outside');

export interface ViewImage {
  src: string;
  description?: string;
}

@Component({ components: { Popup, Spinner }, directives: { ClickOutside } })
export default class ImageViewer extends Vue {
  isOpened = false;
  images: ViewImage[] = [];
  position = 0;
  current: ViewImage | null = null;
  full = false;
  loading = false;
  touchEventsOptions = {
    disableClick: true,
  };

  $refs!: {
    imgBox: HTMLDivElement;
    title: HTMLDivElement;
    img: HTMLImageElement;
    container: HTMLDivElement;
  };

  toggleFull() {
    this.full = !this.full;
  }

  open(images: ViewImage[], position = 0) {
    this.isOpened = true;
    this.images = images;
    this.loading = true;
    this.position = position;
    this.current = images[position];
  }

  close() {
    this.isOpened = false;
    this.loading = false;
    this.full = false;
    this.images = [];
  }

  prev() {
    if (this.images.length === 1) {
      return;
    }
    this.change(this.position - 1);
  }

  next() {
    if (this.images.length === 1) {
      return;
    }
    this.change(this.position + 1);
  }

  change(position) {
    this.position = position;
    if (this.position < 0) {
      this.position = this.images.length - 1;
    } else if (this.position >= this.images.length) {
      this.position = 0;
    }
    this.loading = true;
    this.full = false;
    this.current = this.images[this.position];
  }

  loadImage(): void {
    const { img, container } = this.$refs;
    if (!img || !container) {
      return;
    }
    const containerMaxHeight = container.clientHeight - 90;
    img.style.maxHeight = `${Math.min(
      img.naturalHeight,
      containerMaxHeight
    )}px`;
    this.loading = false;
  }

  noAction() {}
}
