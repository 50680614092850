


































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { OnCreated, OnDestroyed } from '@/core/vue.types';
import config from '@/env';
import {
  scrollBlock,
  scrollUnblock,
  isScrollBlocked,
} from '../fns/scroll-blocker';

@Component
export default class Popup extends Vue implements OnCreated, OnDestroyed {
  private static openedPopups: any[] = [];

  $refs!: {
    popup: HTMLDivElement;
  };

  @Prop()
  show!: boolean;

  created(): void {
    if (this.show) {
      this.onOpening();
    }
  }

  destroyed(): void {
    this.onClosing();
  }

  mock() {
    //stop propagation
  }

  close() {
    this.$emit('onClose');
  }

  onWheel(evt: WheelEvent): void {
    evt.stopPropagation();
  }

  @Watch('show')
  private onChangeShow(newValue) {
    if (newValue) {
      this.onOpening();
    } else {
      this.onClosing();
    }
  }

  private onOpening(): void {
    if (!config.isClient) {
      return;
    }
    if (!Popup.openedPopups.includes(this)) {
      Popup.openedPopups.push(this);
    }
    if (!isScrollBlocked()) {
      scrollBlock();
    }
  }

  private onClosing(): void {
    if (!config.isClient) {
      return;
    }
    if (Popup.openedPopups.includes(this)) {
      Popup.openedPopups = Popup.openedPopups.filter((x) => x != this);
    }
    if (Popup.openedPopups.length === 0 && isScrollBlocked()) {
      scrollUnblock();
    }
  }
}
