function parseImageElement(element) {
  return {
    src: element.dataset.src,
    preview: element.dataset.preview || element.dataset.src,
    description: element.dataset.desc,
    element: element,
  };
}

function getImages(container) {
  return Array.from<HTMLDivElement>(
    container.getElementsByClassName('article__image-holder')
  );
}

export function getSingleImages(container) {
  return getImages(container)
    .filter((el) => el.closest('.article__gallery') == null)
    .map(parseImageElement);
}

export function getGalleries(container) {
  return Array.from<HTMLDivElement>(
    container.getElementsByClassName('article__gallery')
  ).map((element) => ({
    el: element,
    items: getImages(element).map(parseImageElement),
  }));
}
