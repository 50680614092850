























import { Component, Vue, Prop } from 'vue-property-decorator';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';

interface LinkData {
  link: string;
  title: string;
}

@Component({
  components: { BoxContent, Box },
})
export default class List extends Vue {
  @Prop({ type: Array, required: true })
  topics!: Array<LinkData>;

  @Prop({ type: Array, required: true })
  tableOfContent!: Array<LinkData>;

  @Prop({ type: Number, default: 3 })
  defaultViewCount!: number;

  @Prop({
    type: String,
    required: false,
  })
  currentPath!: string;

  @Prop({
    type: String,
    required: false,
  })
  tocCurrent!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  showAll!: boolean;

  get items() {
    const flatten = [...this.topics];
    const currentIndex = flatten.findIndex((x) => x.link === this.currentPath);
    if (currentIndex >= 0 && this.tableOfContent != null) {
      flatten.splice(
        currentIndex + 1,
        0,
        ...this.tableOfContent.map((x) => ({ ...x, isToc: true }))
      );
    }
    return this.showAll ? flatten : flatten.slice(0, this.defaultViewCount);
  }

  scrollTo(link: { link: string; title: string }) {
    this.$track('article_page', 'click_contents_item', link.title);
    document
      .querySelector(`#${link.link}`)
      ?.scrollIntoView({ behavior: 'smooth' });
  }
}
