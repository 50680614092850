

























import { Component, Vue, Prop } from 'vue-property-decorator';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import List from './List.vue';

interface LinkData {
  link: string;
  title: string;
}

@Component({
  components: { BoxContent, Box, List },
})
export default class Links extends Vue {
  @Prop({ type: Array, required: true })
  topics!: Array<LinkData>;

  @Prop({ type: Array, required: true })
  tableOfContent!: Array<LinkData>;

  @Prop({
    type: String,
    required: false,
  })
  currentPath!: string;

  @Prop({
    type: String,
    required: false,
  })
  tocCurrent!: string;

  showAll = false;
  defaultViewCount = 3;

  get availableMore() {
    return (
      this.topics.length + this.tableOfContent.length > this.defaultViewCount
    );
  }

  toggleShowMore() {
    if (this.showAll === false) {
      this.$track('game_guide_page', 'click_show_more', this.$route.fullPath);
    }
    // hack to update sticky block
    // call twice to prevent glitch sticky
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 50);
    }, 50);

    this.showAll = !this.showAll;
  }
}
