import { GameId, Guid, LanguageCode } from '@/core/system';

export interface Review {
  id?: Guid;
  game: GameId;
  language: LanguageCode;
  articlePath: string;
  reaction?: ReviewReaction;
  message?: string;
  closed?: boolean;
}

export enum ReviewReaction {
  Like = 'like',
  Dislike = 'dislike',
}
