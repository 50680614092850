































import { Component, Vue, Prop } from 'vue-property-decorator';
import List from './List.vue';

interface LinkData {
  link: string;
  title: string;
}

@Component({ components: { List } })
export default class ArticleDrawer extends Vue {
  @Prop({ type: Array, required: true })
  topics!: Array<LinkData>;

  @Prop({ type: Array, required: true })
  tableOfContent!: Array<LinkData>;

  @Prop({
    type: String,
    required: false,
  })
  currentPath!: string;

  @Prop({
    type: String,
    required: false,
  })
  tocCurrent!: string;

  opened = false;
}
